'use client'


import { useEffect } from "react";

export const EmbeddedChat = () => {
    useEffect(() => {
        let loaded = true
        import('../embed/chatbot-embed').then(( { onPageLoad }) => {
            if (loaded) onPageLoad()
        })

        return () => { loaded = false }
    }, [])

    return null
}
